@media (max-width: 700px) {
  .error .block-space {
	  margin-bottom: 85px;
	}
}

@media (max-width: 525px) {
  .error a, .error button {
	  width: 100px;
	  margin: 3px;
	  margin-top: 10px;
	}

	.error .block-space {
	  margin-bottom: 50px;
	}
}

@media (max-width: 500px) {
  #status-iframe {
    width: 100%;
  }
}
