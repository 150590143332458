@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url("./../fonts/Poppins-Light.ttf");
}

:root {
	--background_color: #faeca3 !important;
  --primary_color: #222f69;
  --primary_color_light: #2458a4;
  --secondary_color: #068054;
  --menu_item_gray_color: #eef2f6;
  --menu_item_gray_color_2: #dce0e3;

  --neutral_color: #828282;
  --sucess_color: #39bb6c;
  --warning_color: #f48f36;
  --danger_color: #ca4040;
  --info_color: #3590ca;

  --font: Poppins, sans-serif;
}

body {
  font-family: var(--font) !important;
}

img.flag {
	width: 25px;
	height: auto;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.green {
  color: #008000;
}

.loader {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

  background: linear-gradient(-45deg, #f07167, #fdfcdc, #00afb9, #0081a7);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loader img {
	max-height: 100px;
	display: flex;
}

iframe.login {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: none;
}

.MuiListItemAvatar-root {
  text-align: center !important;
}

.loading {
  width: 100%;
  height: 26px;
  text-align: center;
}

iframe.embebbed {
  width: 100%;
  border: 0;
  height: calc(100vh - 251px)
}

/* Profile menu */
.profile-menu {
  background-color: var(--menu_item_gray_color) !important;
  border: none !important;
}

.profile-menu .MuiChip-label {
  color: var(--primary_color) !important;
}

.profile-menu[aria-controls="menu-list-grow"], 
.profile-menu:hover {
  border: none !important;
  background: var(--primary_color) !important;
}

.MuiListItemButton-gutters:hover {
    background-color: var(--primary_color) !important;
}

.MuiListItemButton-gutters:hover .MuiListItemIcon-root {
  color: white !important;
}

.MuiListItemButton-gutters:hover p {
  color: white !important;
}

/* Locale menu */
.MuiAvatar-colorDefault:hover {
  background: var(--primary_color) !important;
  color: white !important;
}

.MuiAvatar-colorDefault:hover .locale-icon {
  color: white !important;
  stroke: none !important;
}

.MuiAvatar-colorDefault[aria-controls="menu-list-grow"], .css-1mjiab4-MuiAvatar-root:hover {
  background: var(--primary_color) !important;
  color: white !important;
}

.locale-list div:hover {
  background: var(--menu_item_gray_color) !important;
}

.locale-list div:hover span {
  color: var(--primary_color) !important;
}

/*Menu toogle*/
.menu-toggle {
  background: var(--primary_color) !important;
  color: white !important;
}

.menu-toggle:hover {
  background: var(--secondary_color) !important;
}

.menu-toggle {
  border-radius: 12px;
}

/*Menu items*/
.menu-item:hover {
  background-color: var(--primary_color) !important;
}

.menu-item:hover .MuiListItemIcon-root {
  color: white !important;
}

.menu-item:hover p {
  color: white !important;
}

.MuiButtonBase-root:hover svg {
  stroke: white !important;
}

.menu-item.Mui-selected {
  color: var(--primary_color) !important;
  background-color: var(--menu_item_gray_color) !important;
}

.menu-item.Mui-selected .MuiListItemIcon-root {
  color: var(--primary_color) !important;
}

table tr:nth-child(even) {
  background-color: #f7f7f7;
}

table tbody tr {
  transition: all ease 0.25s;
}

.link {
  cursor: pointer;
}

table tbody tr:not(.coloured):hover {
  background-color: #f7f7f7;
}

table tr:not(.coloured):nth-child(even):hover {
  background-color: #f2f2f2;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiInputBase-input {
  background: white !important;
  font-weight: normal !important;
}

.MuiOutlinedInput-notchedOutline {
  border-radius: 0 !important;
}

.MuiFormLabel-asterisk {
  color: #f03434;
  font-size: 15px;
}

.form-title {
  text-align: center;
  margin-top: 0;
  padding: 0;
  margin-bottom: 35px;
  color: var(--primary_color);
  font-size: 25px;
  font-weight: normal;
}

.form-subtitle {
  text-align: center;
  margin-top: 0;
  padding: 0;
  margin-top: 30px !important;
  margin-bottom: 15px !important;
  color: var(--primary_color);
  font-size: 17px;
  font-weight: normal;
}

.text-primary {
  color: var(--primary_color);
}

.text-transparent {
  opacity: 0 !important;
}

.text-success {
  color: var(--sucess_color) !important;
}

.text-danger {
  color: var(--danger_color) !important;
}

.MuiButton-sizeSmall {
  padding: 0px 10px;
}

.MuiButton-containedPrimary {
  background-color: var(--primary_color);
}

.MuiButton-containedPrimary:hover {
  background-color: var(--secondary_color);
}

.MuiButton-outlinedPrimary {
  border: 1px solid var(--primary_color);
  color: var(--primary_color);
}

.MuiButton-outlinedPrimary:hover {
  border: 1px solid var(--secondary_color);
  color: var(--secondary_color);
  background: none;
}

label span {
  background: white;
}

.link.back {
  float: left;
  margin-right: 3px;
  margin-top: -1px;
}

.notification-success {
  background-color: #07bc0c;
}

.MuiIconButton-colorPrimary {
  color: var(--primary_color);
}

table tbody tr .MuiStack-root {
  justify-content: right;
  transition: all 0.25s ease;
}

.MuiTableCell-root {
  padding: 9px 16px;
}

.MuiAutocomplete-inputRoot {
  background: none !important;
}

.MuiAutocomplete-tag {
  margin: 0 !important;
}

.subtitle {
  font-style: italic;
  color: var(--primary_color);
  opacity: 0.75;
  font-size: 12px;
}

.MuiButtonBase-root.Mui-disabled {
  opacity: 0;
}

.MuiChip-filled.Mui-disabled {
  opacity: 1;
}

img.table-person-profile {
  width: 25px;
  height: 25px;

  min-width: 25px;
  min-height: 25px;

  border-radius: 50%;
  float: left;
  margin-right: 5px;
}

div.table-person-profile {
  width: 25px;
  height: 25px;

  min-width: 25px;
  min-height: 25px;

  border-radius: 50%;
  float: left;
  margin-right: 5px;
  background-size: cover;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

img.person-profile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.25s ease;
}

img.person-profile:hover {
  transform: scale(1.05);
}

div.person-profile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.25s ease;
  margin: auto;
  left: 0;
  right: 0;
  background-size: cover;
}

div.person-profile:hover {
  transform: scale(1.05);
}

.table-profile-container {
  display: flex;
  /*flex-wrap: nowrap; */
  align-items: center
}

.f-right {
  float: right;
}

label.error {
  color: #f03434;
  display: block;
  font-size: 10px;
}

#profile-section {
  position: relative;
}

#assistedauth-image {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 15px;
  left: 21px;
}

.fillcell {
  background-color: #f7f7f7 !important;
}

table.transparent tr:nth-child(2n) {
  background-color: transparent !important;
}

table.transparent tbody tr:hover {
  background-color: transparent !important;
}

.order-container {
  display: table-row;
}

.MuiGrid-root {
  overflow-y: hidden;
  overflow-x: auto;
  position: unset !important;
}

.MuiButtonBase-root {
  margin-bottom: 0;
}

small {
  background: var(--primary_color_light);
  padding: 2px;
  color: white;
}

.MuiButtonBase-root {
  text-transform: none;
}

small.warning {
  background: var(--warning_color);
  color: white;
}

small.success {
  background: var(--sucess_color);
  color: white;
}

small.danger {
  background: var(--danger_color);
  color: white;
}

#status-iframe {
  width: 450px;
  height: 41px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  overflow: hidden;
}

.MuiTypography-root {
  font-family: var(--font) !important;
}

.MuiListItemButton-root.Mui-selected {
    color: var(--primary_color) !important;
    background-color: var(--menu_item_gray_color) !important;
}

.MuiListItemButton-root.Mui-selected svg {
    stroke: var(--primary_color) !important;
}

.MuiList-root::after {
  opacity: 0;
}

.MuiSwitch-root {
  margin-left: -12px;
}

form .MuiButtonBase-root.Mui-disabled {
  opacity: 1;
}

.MuiButtonBase-root.MuiTab-root {
  margin-bottom: 0;
  color: var(--primary_color);
  transition: all 0.25s ease;
}

.MuiButtonBase-root.MuiTab-root:hover {
  color: var(--secondary_color);
}

.MuiTabs-indicator {
  background-color: var(--primary_color);
}

form .MuiCard-root {
  background: var(--menu_item_gray_color);
}

.tab-card .MuiOutlinedInput-input.Mui-disabled {
  background: var(--menu_item_gray_color) !important;
}

.html_select:disabled {
  background: var(--menu_item_gray_color) !important;
}

.tab-card .MuiOutlinedInput-input:read-only {
  background: var(--menu_item_gray_color) !important;
}

.tab-card .MuiSwitch-root {
  margin-left: 7px;
}

.bold {
  font-weight: bold;
}

.validation-group-error:after {
  content: "!";
  background: #f03434;
  color: white;
  font-size: 20px;
  padding: 0 7px;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  /*border-radius: 0 !important;*/
}

#mui-rte-editor-container {
  background: white !important;
  padding: 25px !important;
  border-radius: 12px !important;
  display: block;
  min-height: 300px;
}

.whse_template_button {
  width: 70%;
  margin-left: 15%;
}

.MuiAutocomplete-popper {
  border: 1px solid var(--primary_color_light);
}

.MuiButtonBase-root.secondary {
  background-color: var(--primary_color_light) !important;
}

.html_select {
  width: 100%;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 16px;
  font-size: 14px;
}

.MuiChip-label {
  overflow: unset !important;
  text-overflow: unset !important;
  white-space: break-spaces;
  line-height: 1.25em;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.MuiChip-root {
  margin: 3px !important;
}

#profile-section .MuiAvatar-root {
  background: none !important;
}

.inline-filter-table {
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-radius: 0;
  margin-top: 15px;
}

.switch-label {
  margin-right: 10px;
}

ul.status-legend {
  list-style-type: none;
  float: left;
}

.status-legend li {
  display: inline;
  padding: 7px 12px;
}

.bg-gray {
  background: #d7d7d7 !important;
}

.bg-pink {
  background: #ffe3fb !important;
}

.bg-green {
  background: #d4e6d0 !important;
}

.bg-blue {
  background: #c6e8f9 !important;
}

.bg-yellow {
  background: #f0edb0 !important;
}

.bg-red {
  background: #ffd0d0 !important;
}

.bg-fushia {
  background: #d3dafd !important;
}

.bg-orange {
  background: #ffe3d3 !important;
}

img.logo {
  width: 150px;
  height: 150px;
}

.break-line {
  width: 100%;
  padding: 0;
  margin: 0;
}

.block-space {
  width: 100%;
  padding: 15px;
}

.option-icon {
  margin-right: 5px;
  font-size: 23px;
}

.option-icon.success {
  color: var(--sucess_color);
}

.option-icon.danger {
  color: var(--danger_color);
}

.option-icon.inverse {
  transform: rotate(180deg);
}

.MuiListItemButton-gutters {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

form .MuiListItemButton-gutters {
  padding: 5px 15px !important;
}

form .MuiListItemButton-gutters:hover {
  background-color: var(--primary_color_light) !important;
}

form .MuiListItemButton-gutters:hover span {
  color: white !important;
}

form .MuiListItemButton-gutters.Mui-selected {
  background-color: var(--menu_item_gray_color_2) !important;
}

form .MuiListItemButton-gutters.Mui-selected span {
  color: var(--secondary_color) !important;
}

.pga-code-fda {
  color: white;
  background: var(--danger_color);
  width: max-content;
  padding: 2px 10px;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 5px;
  margin-top: -8px;
  left: 0;
  right: 0;
}

table.tiny .MuiTableCell-root {
  padding: 0px 16px !important;
  font-size: 13px !important;
}

table.tiny .MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
}

.report p {
  margin: 0;
  text-transform: uppercase;
}

.report td {
  padding-right: 15px;
}

.report a {
  color: var(--primary_color_light);
}

.report table {
  width: 100%;
}

.report .check {
  background: #22b900;
  color: white;
  padding: 1px 5px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
}

.report .title {
  font-size: 19px;
}

.report .hr {
  height: 1px;
  border-bottom : 1px solid black;
  margin-top: 5px;
}

p.label {
  padding: 0 !important;
  margin: 0 !important;
}

.filter-field-container {
  position: relative;
}

.filter-field-clear {
  position: absolute;
  right: 0px;
  top: 8px;
  padding: 1px;
  border-radius: 0px;
  background: var(--primary_color);
  color: white;
  transition: all 0.25s ease;
}

.filter-field-clear:hover {
  background: var(--secondary_color);
  color: white;
}

.filter-field-clear svg {
  width: 15px;
  height: 15px;
}

.leaflet-container {
  width: 100%;  
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.imaging-cell img {
  width: 40px;
  height: 40px;
  margin-right: 7px;
}

.country-flag {
  width: 25px !important;
  height: 19px !important;
  border: 1px solid var(--menu_item_gray_color_2) !important;
}

.MuiTimelineDot-filledSuccess {
  color: white;
  background-color: var(--sucess_color);
}

.MuiSlider-root.Mui-disabled {
  color: var(--primary_color_light);
}

.MuiSlider-thumb {
  background: none;
  box-shadow: none !important;
  border: none;
  width: 30px;
  height: 30px;
  background: url("../images/vessel.svg") no-repeat center;
  background-size: cover;
  margin-top: -15px;
  margin-left: -10px;
}

.MuiSlider-thumb::before {
  display: none;
}

.MuiTimePickerToolbar-separator {
  margin: 0px 3px 10px 2px !important;
}

.MuiPickersToolbarText-root.Mui-selected {
  font-weight: bold !important;
}

.ag-horizontal-left-spacer {
  overflow-x: auto !important;
}

.ag-cell .loading {
  height: 15px;
  position: absolute;
  top: 2px;
  left: -25px;
}

.MuiTablePagination-root.thin .MuiTablePagination-toolbar {
  padding: 0 !important;
  min-height: unset !important;
}

.dropzone {
  text-align: center;
  padding: 25px;
  border: 2px dashed #a4a8ae;
  margin-top: 10px;
  cursor: pointer;
}

.dropzone li {
  display: inline-block;
  border: 1px solid #a4a8ae;
  padding: 5px;
  margin: 3px;
}

ul.validate-list {
  list-style-type: none;
  margin-left: 0 !important;
  padding-left: 0 !important;
}

ul.validate-list li {
  margin-top: 7px;
}

ul.validate-list svg {
  float: left;
  height: 20px;
  margin-right: 0;
}

.error {
  text-align: center;
}

.error .content {
  position: relative;
  z-index: 1000;
}

.error h1 {
  font-size: 85px !important;
  margin-bottom: 5px;
}

.error img {
  height: 125px;
  width: auto;
  margin: auto;
  left: 0;
  right: 0;
  margin-bottom: 25px;
}

.error h3 {
  margin-bottom: 0;
}

.error h5 {
  font-size: 15px;
}

.error a, .error button {
  width: 200px;
  margin: 5px;
  margin-top: 30px;
}

.error .block-space {
  margin-bottom: 150px;
}

.error svg {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

a {
  text-decoration: none !important;
}

.table-layout-white {
  background: white !important;
  border-radius: 10px !important;
}

#mui-rte-root {
  border: 1px solid #bfbfbf !important;
}

.kpi-content h6 {
  font-weight: normal;
}

.top-actions {
  position: absolute;
  top: 15px;
  right: 10px;
}

.top-actions a,
.top-actions button {
  margin-left: 7px;
}

ul.listbox {
  padding: 0;
  margin-top: 25px;
}

ul.listbox li {
  width: 31%;
  list-style-type: none;
  padding: 25px;
  display: inline-block;
  text-align: center;
  background: var(--menu_item_gray_color);
  border-radius: 5px;
  cursor: pointer;
  margin-right: 2%;
  margin-bottom: 2%;
  transition: all 0.25s ease;
}

ul.listbox a {
  color: var(--primary_color);
}

ul.listbox li:hover {
  background: var(--menu_item_gray_color_2);
}

h1.form-title {
  margin: 0;
  font-size: 17px;
  color: var(--primary_color);
  font-weight: normal;
  text-align: left !important;
}

.mr-0 {
  margin-right: 0;
}

.mr-0 .MuiCardContent-root {
  margin-right: -20px;
}

.badge {
  padding: 3px 7px;
  background: var(--primary_color);
  color: white;
  font-size: 12px;
  border-radius: 5px;
}

.bg-neutral {
  background: var(--neutral_color) !important;
}

.bg-danger {
  background: var(--danger_color) !important;
}

.bg-warning {
  background: var(--warning_color) !important;
}

.bg-success {
  background: var(--sucess_color) !important;
}

.bg-info {
  background: var(--info_color) !important;
}
