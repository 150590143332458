@media print {
  @page {
    size: landscape !important;
  }

  header {
    display: none !important;
  }

  nav {
    display: none !important;
  }

  main {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    border-radius: 0 !important;
    background-color: white !important;
    min-height: unset !important;
  }

  .report {
    font-size: 11px !important;
    width: 100% !important;
  }

  .MuiCardHeader-root {
    display: none !important;
  }

  .MuiDivider-root {
    display: none !important;
  }

  .MuiPaper-root {
    border-radius: 0 !important;
    width: 100% !important;
  }

  .MuiCardContent-root {
    padding: 0 !important;
  }

  .MuiPaper-root {
    border: none;
  }

  .hide-print {
    display: none !important;
  }

  .MuiGrid-root {
    display: flex;
  }

  .MuiGrid-item {
    padding-left: 8px;
    padding-top: 8px;
  }

  .report .title {
    fontSize: '15px';
  }

  .MuiGrid-grid-md-2 {
    -webkit-flex-basis: 16.666666%;
    -ms-flex-preferred-size: 16.666666%;
    flex-basis: 16.666666%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 16.666666%;
  }

  .MuiGrid-grid-md-3 {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 25%;
  }

  .MuiGrid-grid-md-4 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }

  .MuiGrid-grid-md-6 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }

  .MuiGrid-grid-md-8 {
    -webkit-flex-basis: 66.666666%;
    -ms-flex-preferred-size: 66.666666%;
    flex-basis: 66.666666%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 66.666666%;
  }
}
